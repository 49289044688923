import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let ToggleLogin = class ToggleLogin extends Vue {
    get defaultKey() {
        return this.$route.params.type;
    }
    tabClick(v) {
        this.$router.replace({
            name: 'login',
            params: {
                type: v,
            },
        });
    }
};
ToggleLogin = __decorate([
    Component({
        name: 'ToggleLogin',
    })
], ToggleLogin);
export default ToggleLogin;

import { JSEncrypt } from 'jsencrypt';
/**
 * 对目标字符串进行RSA加密
 * @param value 目标字符串
 * @param publicKey 公钥内容
 * @returns encryptedStr 加密结果
 */
export const RsaEncrypt = (value, publicKey) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encryptedStr = encrypt.encrypt(value);
    return encryptedStr;
};
export const RsaDecrypt = (value, privateKey) => {
    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(privateKey);
    var decryptMsg = decrypt.decrypt(value);
    return decryptMsg;
};

import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch, } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { ModifyPasswordService, LoginService } from '@/sevices';
import { RsaEncrypt } from '@/common/rsg';
import * as Utils from '@/common/utils';
// import { RsaEncrypt } from '@/common/rsg';
let ModifyPassword = class ModifyPassword extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            newPassword: '',
            comfirmPassword: '',
        };
        this.tip = '';
        this.typeOptions = [
            {
                label: '数字',
                value: 'digit',
            },
            {
                label: '小写字母',
                value: 'lowercaseLetter',
            },
            {
                label: '大写字母',
                value: 'uppercaseLetter',
            },
            {
                label: '英文特殊字符（除空格）',
                value: 'specialCharacter',
            },
        ];
        this.rules = {
            newPassword: [{
                    pattern: /^[^\s]*$/,
                    message: '请不要输入空格',
                }, {
                    max: 32,
                    message: '长度不能超过32位',
                    trigger: 'blur',
                }, { validator: this.newPasswordValid, trigger: 'blur' }],
            comfirmPassword: [
                { validator: this.comfirmPasswordValid, trigger: 'blur' },
            ],
        };
    }
    visibleChange(v) {
        if (v) {
            this.getPassworTip();
        }
        else {
            this.form = {
                newPassword: '',
                comfirmPassword: '',
            };
        }
    }
    onFormChange() {
        Utils.trim(this.form);
    }
    getPassworTip() {
        let str = '';
        this.typeOptions.forEach((item) => {
            if (this.data.passRule[item.value]) {
                if (str.length === 0) {
                    str += item.label;
                }
                else {
                    str += '、' + item.label;
                }
            }
        });
        this.tip = `至少包含${str}，最少${this.data.passRule.passwordLength}位，最高32位`;
    }
    newPasswordValid(rule, value, callback) {
        // const reg = /^[A-Z](?=.*[0-9].*)(?=.*[a-z].*).{5,19}$/;
        const reg = this.evil('/' + this.data.passRule.regularExpression + '/');
        if (!value) {
            callback(new Error('密码不能为空！'));
        }
        else if (!reg.test(value)) {
            callback(new Error(this.tip));
        }
        else {
            callback();
        }
    }
    comfirmPasswordValid(rule, value, callback) {
        if (!value) {
            callback(new Error('密码不能为空！'));
        }
        else if (value !== this.form.newPassword) {
            callback(new Error('两次密码输入不一致，请重新输入!'));
        }
        else {
            callback();
        }
    }
    handleOk() {
        this.$refs.form.validate((valid) => {
            if (valid) {
                this.requestService();
            }
        });
    }
    evil(fn) {
        // 本质是eval()，防止eslint报错
        const Fn = Function; // 一个变量指向Function，防止有些前端编译工具报错
        return new Fn('return ' + fn)();
    }
    cancel() {
        this.$emit('close');
    }
    async requestService() {
        const { data } = await LoginService.getKey();
        var { index, key } = data;
        const newPassword = RsaEncrypt(this.form.newPassword, key);
        const oldPassword = RsaEncrypt(this.data.oldPassword, key);
        const params = {
            oldPassword,
            newPassword,
            index,
        };
        const { success } = await ModifyPasswordService.modifyPassword(params, this.data.baseUserId, this.data.ruleToken);
        if (success) {
            this.$message.success('设置成功！');
            this.$emit('close');
            Utils.removeStorage(this.$store);
        }
        else {
            this.$message.warning('设置新密码失败！');
        }
    }
};
__decorate([
    Prop()
], ModifyPassword.prototype, "visible", void 0);
__decorate([
    Prop()
], ModifyPassword.prototype, "data", void 0);
__decorate([
    Watch('visible')
], ModifyPassword.prototype, "visibleChange", null);
__decorate([
    Watch('form', { deep: true })
], ModifyPassword.prototype, "onFormChange", null);
ModifyPassword = __decorate([
    Component({
        name: 'ChangePassword',
        components: {
            cForm: Form,
            cFormItem: FormItem,
        },
    })
], ModifyPassword);
export default ModifyPassword;

import { __decorate } from "tslib";
import { Component, Ref, Watch, Vue, Prop, } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm';
import { LoginService, OrgManageService, ModifyPasswordService } from '@/sevices';
import { RsaEncrypt } from '@/common/rsg';
import CToggleLogin from './toggle-login';
import Perm from '@/router/permission';
import PermCode from '@/router/perm-code';
import { resetRouter } from '@/router';
import ChangePassword from './change-password-dialog';
import { namespace } from 'vuex-class';
import { UnitType } from '../../typings/organization/org-manage';
const Global = namespace('Global');
const Organization = namespace('Organization');
let UserloginForm = class UserloginForm extends Vue {
    constructor() {
        super(...arguments);
        this.isSubmitabled = true;
        this.submitting = false;
        this.visible = false;
        this.loginError = '';
        this.data = {};
        this.form = {
            username: '',
            password: '',
        };
        this.currentDptId = '';
        this.rules = {
            username: [{ required: true, message: '账号不能为空' }],
            password: [{ required: true, message: '密码不能为空' }],
        };
    }
    // 判断是否是重定向到运行时的
    get isRedirectRuntime() {
        return window.location.href.includes('app_runtime');
    }
    // 判断是否需要重定向
    get isRedirect() {
        return window.location.href.includes('?redirect_url=');
    }
    onFormChange(form) {
        form.username = form.username.replace(/^\s*|\s*$/g, '');
        this.isSubmitabled = !form.username || !form.password;
    }
    async submit() {
        const isError = this.formComp.validate();
        if (!isError)
            return;
        this.submitting = true;
        this.loginError = '';
        try {
            const resKey = await LoginService.getKey();
            if (!resKey.success)
                return;
            const dataKey = resKey.data;
            const { index, key } = dataKey;
            const password = RsaEncrypt(this.form.password, key);
            // rsa加密结束
            const params = {
                username: this.form.username,
                password,
                client_id: 'api',
                index,
            };
            this.data = {
                username: this.form.username,
                oldPassword: this.form.password,
            };
            const resLogin = await LoginService.login(params);
            if (!resLogin.success || !resLogin.data)
                return;
            if (resLogin.data.specification) {
                this.submitting = false;
                this.data.ruleToken = resLogin.data.access_token;
                const resRule = await ModifyPasswordService.getPasswordRuleInfo(this.data.ruleToken);
                if (resRule.success) {
                    this.visible = true;
                    this.data.passRule = resRule.data;
                    this.data.baseUserId = resLogin.data?.userinfo?.id;
                    return;
                }
            }
            this.getToken(resLogin.data);
            this.setInfoToLocal(resLogin.data);
            await this.getAllPerms();
            await this.checkOrg(false);
            // 普通用户或管理员，登录到运行时需要初始化用户
            if (!Perm.isSuperAdmin() && this.loginType === 'user' && this.isRedirectRuntime) {
                await this.initUser();
            }
            this.goPage();
        }
        catch (e) {
            const getErrorStatus = (error) => error.httpStatus || (error.response && error.response.status);
            const status = getErrorStatus(e);
            if (status === 403) {
                this.loginError = '账号或密码有误';
            }
        }
        finally {
            this.submitting = false;
        }
    }
    /**
     * 跳转页面
     * admin登录工作台 —— 工作台
     * admin登录运营后台 —— 运营后台
     * admin登录工作台（带重定向地址，是app_runtime地址） —— 工作台
     * admin登录运营后台（带重定向地址，是运营后台地址）—— 运营后台重定向页面
  
     * 普通用户登录工作台 —— 工作台
     * 普通用户登录运营后台 —— 工作台
     * 普通用户登录工作台（带重定向地址，是app_runtime地址） —— 重定向的运行时页面
     * 普通用户登录运营后台（带重定向地址， 是运营后台地址）—— 工作台
  
     * 管理员登录工作台 —— 工作台
     * 管理员登录运营后台 —— 运营后台有权限的页面
     * 管理员登录工作台（带重定向地址，是app_runtime地址） —— 重定向的运行时页面
     * 管理员登录运营后台（带重定向地址，是运营后台地址）—— 重定向的运营后台有权限的页面
    */
    goPage() {
        const isAdmin = Perm.isAdmin();
        const isSuperAdmin = Perm.isSuperAdmin();
        // 工作台登录
        if (this.loginType === 'user') {
            // 如果需要重定向到运行时，admin用户直接跳到工作台
            if (this.isRedirect) {
                if (this.isRedirectRuntime) {
                    if (isSuperAdmin) {
                        this.goPlatformHome();
                    }
                    else {
                        this.goRedirectPage();
                    }
                }
                else {
                    this.goRedirectPage();
                }
            }
            else {
                this.goPlatformHome();
            }
        }
        // 运营后台登录
        if (this.loginType === 'operation') {
            if (isAdmin || isSuperAdmin) {
                if (this.isRedirect) {
                    this.goRedirectPage();
                }
                else {
                    this.goFrontend();
                }
            }
            else {
                this.goPlatformHome();
            }
        }
    }
    goRedirectPage() {
        const redirectUrl = this.$route.query.redirect_url;
        window.location.href = redirectUrl.replace('hashsymbol', '#');
    }
    /**
     * 跳到工作台
     */
    goPlatformHome() {
        this.$router.replace({
            name: 'work-platform-home',
        }).catch(() => { });
    }
    /**
    * 登录成功后，跳转到运营后台对应的页面
    */
    goFrontend() {
        const origin = '';
        let url = `${origin}/app-manage`;
        resetRouter();
        const isSysAdmin = Perm.isSysAdmin();
        const sysManage = Perm.hasCodePerm(PermCode.sysManage.sysManage);
        const organization = Perm.hasCodePerm(PermCode.organization.organization);
        const workflowManage = Perm.hasCodePerm(PermCode.flowManage.workflowManage);
        const appManage = Perm.hasCodePerm(PermCode.appManage.appManage);
        if (!isSysAdmin) {
            if (sysManage) {
                url = `${origin}/system-manage`;
            }
            if (organization) {
                url = `${origin}/organization`;
            }
            if (workflowManage) {
                url = `${origin}/flow-manage`;
            }
            if (appManage) {
                url = `${origin}/app-manage`;
            }
        }
        this.$router.push({
            path: url,
        });
    }
    /**
     * 普通用户登录需要获取组织信息
     */
    async initUser() {
        const corpId = await this.getOrgCorpId();
        if (!corpId)
            return;
        await this.setOrg(corpId, this.currentDptId);
        await this.getLoginInfo();
    }
    /**
     * 获取token
     */
    async getToken(data) {
        const token = data.access_token;
        localStorage.setItem('token', token);
    }
    setInfoToLocal(data) {
        const userInfo = {
            name: data.userinfo ? data.userinfo.name : '-',
            username: data.userinfo ? data.userinfo.username : '-',
            mobile: data.userinfo.mobile || '-',
            userId: data.user_id,
            baseUserId: data.userinfo.id,
            imgUrl: data.userinfo.imgUrl,
        };
        const publicUserInfo = userInfo;
        localStorage.setItem('publicUserInfo', JSON.stringify(publicUserInfo));
    }
    /**
     * 重定向时需要获取用户信息并保存到缓存
     */
    async getLoginInfo() {
        const res = await OrgManageService.getLoginInfo();
        if (res.success) {
            const data = res.data || {};
            const user = {
                userInfo: { id: data.id, name: data.name, username: data.username, type: UnitType.User },
                departmentInfo: { id: data?.mainDepartmentId, name: data?.mainDepartmentName, type: UnitType.Dept },
            };
            // 运行态的选人控件默认值需要用到
            localStorage.setItem('user', JSON.stringify(user));
        }
        else {
            throw new Error('获取用户信息异常');
        }
    }
    /**
     * 获取组织CorpId
     */
    async getOrgCorpId() {
        const { data = [], success } = await OrgManageService.getOrgList();
        if (!success)
            throw new Error('获取组织异常');
        if (!Array.isArray(data))
            return;
        if (data) {
            const curOrg = data[0]?.id;
            if (data[0].departments && data[0].departments.length > 0) {
                this.currentDptId = data[0].departments[0]?.id;
            }
            return curOrg;
        }
    }
    /**
     * 设置组织
     */
    async setOrg(id, currentDptId) {
        const param = { orgId: id, currentDptId };
        const { success, data = {}, } = await OrgManageService.setOrg(param);
        if (success) {
            // 流程组需要用到
            localStorage.setItem('corpInfo', JSON.stringify(data));
        }
        else {
            throw new Error('设置组织异常');
        }
    }
    closeIt() {
        this.visible = false;
    }
};
__decorate([
    Global.Action('getAllPerms')
], UserloginForm.prototype, "getAllPerms", void 0);
__decorate([
    Organization.Action('checkOrg')
], UserloginForm.prototype, "checkOrg", void 0);
__decorate([
    Prop()
], UserloginForm.prototype, "loginType", void 0);
__decorate([
    Ref()
], UserloginForm.prototype, "formComp", void 0);
__decorate([
    Watch('form', { deep: true, immediate: true })
], UserloginForm.prototype, "onFormChange", null);
UserloginForm = __decorate([
    Component({
        name: 'UserloginForm',
        components: {
            cForm: Form,
            cFormItem: FormItem,
            CToggleLogin,
            ChangePassword,
        },
    })
], UserloginForm);
export default UserloginForm;
